import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Layout, StyledUl, Button, MenuItem, CheckMark, CheckMarkContainer} from './styles';

class StyledDropDown extends Component {
  constructor(props) {
    super();
    this.state = {
      isOpen: false,
      title: props.title
    }
  }

  showMenuItems = () => {
    this.setState((prev) => ({
      isOpen: !prev.isOpen
    }));
  };

  componentDidMount() {
    if(this.props.isAdventurePage) {
      const stringArray = this.props.location.pathname.split('/');
      if(stringArray[stringArray.length - 1] !== 'videos-library' && 
        stringArray[stringArray.length - 1] !== 'adventure' && 
          stringArray[stringArray.length - 1] !== 'webinar' &&
        stringArray[stringArray.length - 1] !== 'recipes'
      ) {
        let dropdownTitle = this.props.location.pathname.includes('recipes')  ? this.props.location.pathname.includes('category') ?  stringArray[stringArray.length - 1]  : 'Recipe Categories' :stringArray[stringArray.length - 1];
        this.setState({
          title: dropdownTitle
        })
      } else {
        this.setState({
          title: this.props.title
        });
      }
    }
    document.addEventListener('click', this.closeDropDown);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeDropDown);
  }

  closeDropDown = (event) => {
    if ((this.wrapperRef && !this.wrapperRef.contains(event.target)) && this.state.isOpen) {
      this.setState({
        isOpen: false
      })
    }
  };

  componentDidUpdate(prevProps) {
    if(this.props.isAdventurePage) {
      if(prevProps.location !== this.props.location || this.props.title !== prevProps.title) {
        const stringArray = this.props.location.pathname.split('/');
        if(stringArray[stringArray.length - 1] !== 'videos-library' && 
            stringArray[stringArray.length - 1] !== 'adventure' &&
              stringArray[stringArray.length - 1] !== 'webinar' &&
          stringArray[stringArray.length - 1] !== 'recipes'
        ) {
          let dropdownTitle = this.props.location.pathname.includes('recipes')  ? this.props.location.pathname.includes('category') ?  stringArray[stringArray.length - 1]  : 'Recipe Categories' :stringArray[stringArray.length - 1];
          this.setState({
            title: dropdownTitle
          })
        } else {
          this.setState({
            title: this.props.title
          });
        }
      }
    }
  }

  selectMenuItem = (item) => {
    const { history, isAdventurePage, eventKey, onSelect } = this.props;
    onSelect(item);
    this.setState({
      title: item,
      isOpen: false
    });

    if(isAdventurePage) {
      if(eventKey === 1) {
        history.push(`/education/adventure/${item.toLowerCase()}`);
      } else if(eventKey === 2) {
        history.push(`/education/videos-library/${item.toLowerCase()}`);
      } else if(eventKey === 3) {
        history.push(`/education/recipes/category/${item.toLowerCase()}`);
      }
      else{
        history.push(`/education/webinar/${item.toLowerCase()}`);
      }
    }
  };

  render() {
    const { isOpen, title } = this.state;
    const { MenuItems, isCompanyDashboard, isReports, style} = this.props;
    return (
      <Layout isOpen={isOpen} isCompanyDashboard={isCompanyDashboard} style={{"width":"70%", ...style}}>
        <div ref={(node) => this.wrapperRef = node}>
          <Button onClick={this.showMenuItems} isCompanyDashboard={isCompanyDashboard} flex={1} isReports={isReports}>
            <div className={isCompanyDashboard ? "companyname" : "title"}>{title}</div>
            <div className="dropDown">
              <span className="caret" />
            </div>
          </Button>
          <div>
            {
              isOpen &&
              <StyledUl>
                {
                  MenuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => this.selectMenuItem(item)}
                      isActive={title.toLowerCase() === item.toLowerCase()}
                    >
                      <CheckMarkContainer>
                        <CheckMark />
                      </CheckMarkContainer>
                      {item}
                    </MenuItem>
                  ))
                }
              </StyledUl>
            }
          </div>
        </div>
      </Layout>
    )
  }
}

StyledDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  MenuItems: PropTypes.array.isRequired,
  isCompanyDashboard: PropTypes.bool,
  isAdventurePage: PropTypes.bool,
  eventKey: PropTypes.number,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  isReports: PropTypes.bool,
  style:PropTypes.string,
};

export default withRouter(StyledDropDown);