import styled, { css } from 'styled-components';

const Layout = styled.div`
  width: 100%;
  border-radius: 19px;
  position: relative;
  bottom: 0px;
  margin-left: 70px;
  ${({ isOpen }) => isOpen && css`
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}
  > div {
    > div {
      background-color: #dedcdc;
      position: relative;
    }
  }

  ${({ isCompanyDashboard }) => isCompanyDashboard && css`
    float: left;
    > div {
      > div {
        float: left;
        width: 100%;
      }
    }
  `}
`;

const Button = styled.button`
  width: 60%;
  margin-left: 144px;
  justify-content: flex-end;
  background-color: rgb(250, 249, 244);
  border: 1px solid rgb(175, 203, 211);
  padding: 9px 10px;
  border-radius: 6px;
  color: rgb(0, 92, 135);
  text-align: left;
  line-height: 20px;
  font-size: 14px;
  position: relative;
  text-transform: capitalize;
  display: ${({flex}) => flex && 'flex'};
  @media (max-width: 500px) {
    justify-content: center;
    margin-top: ${({isReports}) => isReports ? '0px' : '-58px'};
  }
  .title {
    margin: 0 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Rubik-Regular;
    text-align: right;
  }
  .companyname {
    margin: 0 5px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Rubik-Regular;
    width: 132px;
    font-size: 16px;
    @media (max-width: 500px){
      width: unset;
    }
  }
  .dropDown {
    display: inline-block;
    text-align: right;
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      vertical-align: middle;
      border-top: 6px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      float: right;
      margin-top: 7px;
      margin-left: 7px;
    }
  }

  > svg {
    float: left;
    margin-right: 7px;
    margin-top: 2px;
  }

  &:hover, &:active, &:focus {
    outline: none;
  }

  ${({ isCompanyDashboard }) => isCompanyDashboard && css`
    float: left;
  `}
`;

const StyledUl = styled.ul`
  list-style-type: none;
  padding: 16px;
  position: absolute;
  width: 100%;
  background-color: #282C37;
  z-index: 1000;
  max-height: 350px;
  overflow-y: auto;
  opacity: 0.9;
  border-radius: 4px;
`;

const MenuItem = styled.li`
.company-initiative{
  line-height: 24px;
  cursor: pointer;
  position: relative;
  padding: 5px;
  ${'' /* font-size: 14px; */}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${'' /* margin: 0 0 11px 0; */}
  ${'' /* color: #fff; */}
  border-radius: 4px;
  ${'' /* opacity: 0.6 */}
  color: rgb(0, 92, 135) !important;
    font-family: Rubik-Medium;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
}
  line-height: 24px;
  cursor: pointer;
  position: relative;
  padding: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 11px 0;
  color: #fff;
  border-radius: 4px;
  opacity: 0.6
  ${({ isActive }) => isActive && css`
    background-color: #979797;
    color: #fff
    opacity: 1

    > div {
      display: inline-block;
    }
  `}
`;

const CompanyInitiativeMenuItem = styled.li`
  line-height: 24px;
  cursor: pointer;
  position: relative;
  padding: 5px;
  ${'' /* font-size: 14px; */}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${'' /* margin: 0 0 11px 0; */}
  ${'' /* color: #fff; */}
  border-radius: 4px;
  ${'' /* opacity: 0.6 */}
  color: rgb(0, 92, 135) !important;
    font-family: Rubik-Medium;
    font-style: normal;
    font-size: 12px;
    line-height: 20px;

  ${({ isActive }) => isActive && css`
    background-color: #979797;
    color: #fff
    opacity: 1

    > div {
      display: inline-block;
    }
  `}
`;

const CheckMark = styled.span`
  display:block;
  margin: 1px 0 0 3px;
  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 6px;
    border-style: solid;
    border-color: #282C37;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;
const CheckMarkContainer = styled.div`
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
  background-color:#fff;
  margin: 0 4px 0 4px
`;
export { Layout, StyledUl, Button, MenuItem, CheckMark, CheckMarkContainer, CompanyInitiativeMenuItem}